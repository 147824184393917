import { Fade } from "react-awesome-reveal";
import uniIcon from "../../images/icons/graduation-cap.svg";
import "./styles.css";

export default function EducationCard({
  id,
  institution,
  course,
  startYear,
  endYear,
}) {
  return (
    <Fade triggerOnce direction="up">
      <div key={id} className="education-card">
        <div className="educard-img" style={{ backgroundColor: "#7cb4fc" }}>
          <img src={uniIcon} alt="" />
        </div>
        <div className="education-details">
          <h6 style={{ color: "#89d3fd" }}>
            {startYear}-{endYear}
          </h6>
          <h4 style={{ color: "#ffbe55" }}>{course}</h4>
          <h5 style={{ color: "#6ba2eb" }}>{institution}</h5>
        </div>
      </div>
    </Fade>
  );
}
