import {
  estudeioImg,
  happierImg,
  myteam,
  paymentdapp,
  pomodoro,
  utfhubImg,
} from "../images/projects";

export const projectsData = [
  {
    id: 1,
    projectName: "Pomodoro",
    projectDesc:
      "This project focuses on creating a customizable and responsive pomodoro website. The colors and timer can be customized to suit individual preferences. Through this project, the developer gained hands-on experience with Next.js 13 while creating a practical tool for productivity enhancement.",
    tags: ["Next.js", "Redux", "Styled-Components"],
    code: "https://github.com/raulval/pomodoro-app",
    demo: "https://pomodoro-app-raulval.vercel.app/",
    image: pomodoro,
  },
  {
    id: 2,
    projectName: "MyTeam",
    projectDesc:
      "This project involves creating a responsive landing page of a company that assists other companies in forming dynamic teams with top global talent. The focus is on utilizing Next.js 13 to build a dynamic and interactive user interface. The project aims to showcase the developer's skills in Next.js while providing a full responsive page.",
    tags: ["Next.js", "React Hook Form", "Yup", "Styled-Components"],
    code: "https://github.com/raulval/myteam-website",
    demo: "https://myteam-landing-page.vercel.app/",
    image: myteam,
  },
  {
    id: 3,
    projectName: "Payment dApp",
    projectDesc:
      "In this hands-on project, I dive into the world of web3 and blockchain technology, specifically focusing on the Polygon network. My goal is to develop a peer-to-peer payment decentralized application (dApp) that allows users to securely send tokens to another address. Throughout this project, I gain practical experience in building blockchain-based applications. The resulting dApp offers a seamless and user-friendly interface, ensuring hassle-free token transfers between addresses.",
    tags: ["Next.js", "Ethers.js", "Tailwind CSS", "Polygon", "Solidity"],
    code: "https://github.com/raulval/payment-dapp",
    demo: "https://payment-dapp-raulval.vercel.app/",
    image: paymentdapp,
  },
  {
    id: 4,
    projectName: "Lottery Betting App",
    projectDesc:
      "This project is a learning experience focused on mastering React Native. The goal is to develop an app that allows users to place bets on lotteries. By immersing in React Native, the developer gains practical knowledge in mobile app development, including user interface design and functionality implementation. The app provides an intuitive and engaging platform for users to participate in lottery betting, combining the power of React Native with the excitement of playing the lottery.",
    tags: ["React Native", "Redux", "Expo", "Styled-Components", "Yup"],
    code: "https://github.com/raulval/tgl-react-native",
    // demo: "https://github.com/raulval/tgl-react-native",
    // image: placeholder,
  },
  {
    id: 5,
    projectName: "Happier",
    projectDesc:
      "Happier is a project created as a final work for the Web Programming 1 course at UTFPR-CP. It aims to connect people to visit entities such as asylums and orphanages.",
    tags: ["ReactJS", "Redux", "Firebase"],
    code: "https://github.com/raulval/happier",
    demo: "https://github.com/raulval/happier",
    image: happierImg,
  },
  {
    id: 5,
    projectName: "Estude.io",
    projectDesc:
      "This project was created for Cycle 2 of the Web Programming 2 course at UTFPR-CP. It aims to create notes and tasks for students to organize themselves.",
    tags: ["ReactJS", "Redux ", "Nodejs", "MongoDB"],
    code: "https://github.com/raulval/estude.io",
    // demo: "https://github.com/raulval/estude.io",
    image: estudeioImg,
  },
  {
    id: 6,
    projectName: "UTFHub",
    projectDesc:
      "This project was created as a final work for the course of Integration Workshop 2 at UTFPR-CP. It aims to connect UTFPR students for course discussions.",
    tags: ["ReactJS", "Redux", "Spring Boot", "PostgreSQL"],
    code: "https://github.com/raulval/UTFHub",
    demo: "https://utfhub.netlify.app/",
    image: utfhubImg,
  },
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
