import { BsPlayFill } from "react-icons/bs";
import { FaCode } from "react-icons/fa";
import { placeholder } from "../../images/projects";
import "./styles.css";

export default function ProjectCard({
  id,
  name,
  desc,
  tags,
  code,
  demo,
  image,
}) {
  return (
    <div
      key={id}
      className="projectCard"
      style={{ backgroundColor: "#213149" }}
    >
      <div className="projectContent">
        <h2
          id={name.replace(" ", "-").toLowerCase()}
          style={{ color: "#7cb4fc" }}
        >
          {name}
        </h2>
        <img src={image ? image : placeholder} alt={name} loading="lazy" />
        <div className="project-showcaseBtn">
          {demo && (
            <a
              href={demo}
              target="_blank"
              rel="noopener noreferrer"
              className="iconBtn"
              aria-labelledby={`${name.replace(" ", "-").toLowerCase()} ${name
                .replace(" ", "-")
                .toLowerCase()}-demo`}
            >
              <BsPlayFill
                id={`${name.replace(" ", "-").toLowerCase()}-demo`}
                className="icon"
                aria-label="Demo"
                size="2em"
              />
            </a>
          )}
          <a
            href={code}
            target="_blank"
            rel="noopener noreferrer"
            className="iconBtn"
            aria-labelledby={`${name.replace(" ", "-").toLowerCase()} ${name
              .replace(" ", "-")
              .toLowerCase()}-code`}
          >
            <FaCode
              id={`${name.replace(" ", "-").toLowerCase()}-code`}
              className="icon"
              aria-label="Code"
            />
          </a>
        </div>
      </div>
      <div className="project-desc">
        <p
          style={{
            color: "white",
          }}
        >
          {desc}
        </p>
      </div>
      <div
        className="project-lang"
        style={{
          background: "#3a5070",
          color: "white",
        }}
      >
        {tags.map((tag, id) => (
          <span key={id}>{tag}</span>
        ))}
      </div>
    </div>
  );
}
