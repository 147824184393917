import { Fade } from "react-awesome-reveal";
import Typewriter from "typewriter-effect";
import { educationData } from "../../data/educationData";
import graduating from "../../images/graduating.svg";
import EducationCard from "./educationCard";
import "./styles.css";

export default function Education() {
  return (
    <div className="education">
      <section className="section is-large">
        <header className="has-text-centered mb-6">
          <Fade triggerOnce>
            <h1 className="is-size-3 has-text-weight-bold">
              <Typewriter
                options={{
                  strings: ["Education", "Escolaridade"],
                  autoStart: true,
                  loop: true,
                }}
              />
            </h1>
          </Fade>
        </header>
        <div className="education-body">
          <div className="education-description">
            {educationData.map((edu) => (
              <EducationCard
                key={edu.id}
                id={edu.id}
                institution={edu.institution}
                course={edu.course}
                startYear={edu.startYear}
                endYear={edu.endYear}
              />
            ))}
          </div>
          <Fade triggerOnce direction="right">
            <div className="education-image">
              <img src={graduating} alt="Graduating Image" />
            </div>
          </Fade>
        </div>
      </section>
    </div>
  );
}
