import "./styles.css";

export default function Footer() {
  return (
    <footer>
      <p className="copyright">
        Made with{" "}
        <span role="img" aria-label="thinking">
          ❤️
        </span>{" "}
        by{" "}
        <a
          href="https://www.linkedin.com/in/raulval/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Raul Val
        </a>
      </p>
    </footer>
  );
}
