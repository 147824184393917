import { useForm, ValidationError } from "@formspree/react";
import React from "react";
import toast from "react-hot-toast";
import "./styles.css";

export default function ContactForm() {
  const [state, handleSubmit] = useForm("xjvlerzw");
  // console.log(state.errors.message);

  if (state.errors.length > 0) {
    toast.error(state.errors[0].message, {
      id: "error",
    });
  } else if (state.succeeded) {
    toast.success("Thanks for the message! I will answer ASAP", {
      id: "success",
      duration: 6000,
    });
  }

  return (
    <div className="contact-form">
      <form onSubmit={handleSubmit}>
        <div className="field">
          <label className="label" htmlFor="full-name">
            Name
          </label>
          <input
            className="input is-dark"
            type="text"
            name="name"
            id="full-name"
            required
          />
        </div>
        <div className="field">
          <label className="label" htmlFor="email">
            Email Address
          </label>
          <input
            className="input is-dark"
            id="email"
            type="email"
            name="email"
            required
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </div>
        <div className="field">
          <label className="label" htmlFor="subject">
            Subject
          </label>
          <input
            className="input is-dark"
            name="subject"
            id="subject"
            required
          />
        </div>
        <div className="field">
          <label className="label" htmlFor="message">
            Message
          </label>
          <textarea
            className="textarea is-dark"
            id="message"
            name="message"
            placeholder="A beautiful message to happy our days!"
            required
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
        </div>
        <div className="field">
          <button
            className="button is-medium is-fullwidth is-responsive"
            type="submit"
            disabled={state.submitting}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
