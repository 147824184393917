import {
  nextjs,
  reactNative,
  css,
  html,
  javascript,
  mongo,
  nodejs,
  postgres,
  reactjs,
  typescript,
  styledComponents,
  redux,
  tailwind,
  nestjs,
  prisma,
  graphql,
  flutter,
  solidjs,
  web3,
  blockchain,
} from "../images/icons";

const Front = [
  {
    skill: "Typescript",
    logo: typescript,
  },
  {
    skill: "Javascript",
    logo: javascript,
  },
  {
    skill: "React.js",
    logo: reactjs,
  },
  {
    skill: "React Native",
    logo: reactNative,
  },
  {
    skill: "Next.js",
    logo: nextjs,
  },
  {
    skill: "Redux",
    logo: redux,
  },
  {
    skill: "Styled-Components",
    logo: styledComponents,
  },
  {
    skill: "Tailwind CSS",
    logo: tailwind,
  },
  {
    skill: "HTML 5",
    logo: html,
  },
  {
    skill: "CSS 3",
    logo: css,
  },
];
const Back = [
  {
    skill: "Nest JS",
    logo: nestjs,
  },
  {
    skill: "Prisma ORM",
    logo: prisma,
  },
  {
    skill: "GraphQL",
    logo: graphql,
  },
  {
    skill: "Node JS",
    logo: nodejs,
  },
  {
    skill: "MongoDB",
    logo: mongo,
  },
  {
    skill: "PostgreSQL",
    logo: postgres,
  },
];
const Learning = [
  {
    skill: "Flutter",
    logo: flutter,
  },
  {
    skill: "Solid JS",
    logo: solidjs,
  },
  {
    skill: "Web3",
    logo: web3,
  },
  {
    skill: "Blockchain",
    logo: blockchain,
  },
];
export { Front, Back, Learning };
