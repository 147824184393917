import { Fade } from "react-awesome-reveal";
import { Toaster } from "react-hot-toast";
import Typewriter from "typewriter-effect";
import ContactForm from "./contactForm/ContactForm";
import "./styles.css";
import Terminal from "./terminal/Terminal";

export default function Contact() {
  return (
    <div className="contact container">
      <Toaster position="top-center" />
      <section className="section is-small">
        <header className="has-text-centered mb-6">
          <Fade triggerOnce>
            <h1 className="is-size-3 has-text-weight-bold">
              <Typewriter
                options={{
                  strings: ["Contact", "Contato"],
                  autoStart: true,
                  loop: true,
                }}
              />
            </h1>
          </Fade>
        </header>
        <div className="contact-content">
          <Fade triggerOnce direction="left">
            <Terminal />
          </Fade>
          <Fade triggerOnce direction="right">
            <ContactForm />
          </Fade>
        </div>
      </section>
    </div>
  );
}
