import "./styles.css";

export default function Terminal() {
  return (
    <>
      <section className="window">
        <header className="window-header">
          <nav className="window-controls">
            <span className="control-item control-minimize js-minimize">‒</span>
            <span className="control-item control-maximize js-maximize">□</span>
            <span className="control-item control-close js-close">˟</span>
          </nav>
        </header>
        <main className="window-content">
          <div className="window-cursor">
            <p className="i-path" style={{ marginBottom: "30px" }}>
              <span className="i-path-indicator1">raulval@web</span>
              <span className="i-path-indicator2">:</span>
              <span className="i-path-indicator3">~</span>
              <span className="i-path-indicator4">$</span>
              <span className="window-input">How to find me?</span>
            </p>
            <p className="i-path">
              <span className="i-path-indicator1">raulval@web</span>
              <span className="i-path-indicator2">:</span>
              <span className="i-path-indicator3">~</span>
              <span className="i-path-indicator4">$</span>
              <span className="window-input">
                Email:{" "}
                <a
                  href="mailto:raulval@pm.me"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  raulval@pm.me
                </a>
              </span>
            </p>
            <p className="i-path">
              <span className="i-path-indicator1">raulval@web</span>
              <span className="i-path-indicator2">:</span>
              <span className="i-path-indicator3">~</span>
              <span className="i-path-indicator4">$</span>
              <span className="window-input">
                Telegram:{" "}
                <a
                  href="http://t.me/raul_val"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  raul_val
                </a>
              </span>
            </p>
            <p className="i-path">
              <span className="i-path-indicator1">raulval@web</span>
              <span className="i-path-indicator2">:</span>
              <span className="i-path-indicator3">~</span>
              <span className="i-path-indicator4">$</span>
              <span className="window-input">
                Location:{" "}
                <a
                  href="https://www.google.com/maps/place/Assis,+SP/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Assis, São Paulo - Brazil
                </a>
              </span>
            </p>
            <p className="i-path" style={{ marginTop: "30px" }}>
              <span className="i-path-indicator1">raulval@web</span>
              <span className="i-path-indicator2">:</span>
              <span className="i-path-indicator3">~</span>
              <span className="i-path-indicator4">$</span>
              <span className="window-input">
                Want to get in touch? Fill out the form.
              </span>
            </p>
            <p className="i-path" style={{ marginTop: "30px" }}>
              <span className="i-path-indicator1">raulval@web</span>
              <span className="i-path-indicator2">:</span>
              <span className="i-path-indicator3">~</span>
              <span className="i-path-indicator4">$</span>
              <span className="window-input">Thanks for passing by!</span>
              <span className="i-cursor-underscore"></span>
            </p>
          </div>
        </main>
      </section>
      <div className="prompt-shortcut i-prompt hidden js-open"></div>
    </>
  );
}
