import { Fade } from "react-awesome-reveal";
import Typewriter from "typewriter-effect";
import { Back, Front, Learning } from "../../data/skillsData";
import "./styles.css";

export default function Skills() {
  return (
    <div className="skills container">
      <section className="section is-medium">
        <header className="has-text-centered mb-6">
          <Fade triggerOnce>
            <h1 className="is-size-3 has-text-weight-bold">
              <Typewriter
                options={{
                  strings: ["Skills", "Habilidades"],
                  autoStart: true,
                  loop: true,
                }}
              />
            </h1>
          </Fade>
        </header>
        <Fade triggerOnce cascade damping={0.2} direction="left">
          {Front?.length > 0 && (
            <div className="container is-max-desktop">
              <h3 className="is-size-5 ml-6">Front-End</h3>
              <div className="skills-body mt-4 columns is-multiline is-centered">
                {Front.map((x, index) => (
                  <Fade
                    triggerOnce
                    cascade
                    damping={0.2}
                    direction="left"
                    key={index}
                  >
                    <div
                      className="skills-content is-flex column is-flex-direction-column is-align-items-center mb-4 mr-4"
                      tabIndex="0"
                      role="img"
                      aria-label={x.skill}
                      key={index}
                    >
                      <img
                        src={x.logo}
                        alt={x.skill}
                        loading="lazy"
                        className=" mb-1"
                        width="70px"
                        height="70px"
                      />
                      <p className="is-responsive">{x.skill}</p>
                    </div>
                  </Fade>
                ))}
              </div>
            </div>
          )}
          {Back?.length > 0 && (
            <div className="container is-max-desktop mt-5">
              <h3 className="is-size-5 ml-6">Back-End</h3>
              <div className="skills-body mt-4 columns is-multiline is-centered">
                {Back.map((x, index) => (
                  <Fade
                    triggerOnce
                    cascade
                    damping={0.2}
                    direction="left"
                    key={index}
                  >
                    <div
                      className="skills-content is-flex column is-flex-direction-column is-align-items-center mb-4 mr-4"
                      tabIndex="0"
                      role="img"
                      aria-label={x.skill}
                      key={index}
                    >
                      <img
                        src={x.logo}
                        alt={x.skill}
                        loading="lazy"
                        className="is-two-thirds mb-1"
                        width="70px"
                        height="70px"
                      />
                      <p className="is-responsive">{x.skill}</p>
                    </div>
                  </Fade>
                ))}
              </div>
            </div>
          )}
          {Learning?.length > 0 && (
            <div className="container is-max-desktop mt-5">
              <h3 className="is-size-5 ml-6">Learning</h3>
              <div className="skills-body mt-4 columns is-multiline is-centered">
                {Learning.map((x, index) => (
                  <Fade
                    triggerOnce
                    cascade
                    damping={0.2}
                    direction="left"
                    key={index}
                  >
                    <div
                      className="skills-content is-flex column is-flex-direction-column is-align-items-center mr-4"
                      tabIndex="0"
                      role="img"
                      aria-label={x.skill}
                    >
                      <img
                        src={x.logo}
                        alt={x.skill}
                        loading="lazy"
                        className="is-two-thirds mb-1"
                        width="70px"
                        height="70px"
                      />
                      <p className="is-responsive">{x.skill}</p>
                    </div>
                  </Fade>
                ))}
              </div>
            </div>
          )}
        </Fade>
      </section>
    </div>
  );
}
