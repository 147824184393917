import { Fade } from "react-awesome-reveal";
import { FiGithub } from "react-icons/fi";
import Typewriter from "typewriter-effect";
import { projectsData } from "../../data/projectsData";
import ProjectCard from "./projectCard";
import "./styles.css";

export default function Projects() {
  return (
    <div className="projects-container">
      <section className="section is-medium">
        <header className="has-text-centered mb-6">
          <Fade triggerOnce>
            <h1 className="is-size-3 has-text-weight-bold">
              <Typewriter
                options={{
                  strings: ["Projects", "Projetos"],
                  autoStart: true,
                  loop: true,
                }}
              />
            </h1>
          </Fade>
        </header>
        {projectsData.length > 0 && (
          <div
            className="projects"
            id="projects"
            // style={{ backgroundColor: "#6ba2eb" }}
          >
            <div className="projects-body">
              <div className="projects-bodyContainer">
                <Fade triggerOnce cascade damping={0.1} direction="down">
                  {projectsData.slice(0, 5).map((project) => (
                    <ProjectCard
                      key={project.id}
                      id={project.id}
                      name={project.projectName}
                      desc={project.projectDesc}
                      tags={project.tags}
                      code={project.code}
                      demo={project.demo}
                      image={project.image}
                    />
                  ))}
                </Fade>
              </div>
              <Fade triggerOnce>
                <div className="projects-viewAll">
                  <a href="https://github.com/raulval">
                    <button className="viewAllBtn">
                      View All
                      <FiGithub className="viewArr" />
                    </button>
                  </a>
                </div>
              </Fade>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}
