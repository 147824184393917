import { SiHackthebox } from "react-icons/si";
import github from "../../images/social/github.png";
import linkedin from "../../images/social/linkedin.png";
import "./styles.css";

export default function Home() {
  return (
    <div id="content">
      <div id="wargames">
        <pre>
          {/* prettier-ignore */}
          <code>
            <br /><br /><br />
            GREETINGS %USER%, MY NAME IS RAUL VAL. <br /><br /><br /><br />

            I'M A FRONT-END DEVELOPER AND CYBERSECURITY ENTHUSIAST <br />
            MAJORING IN SOFTWARE ENGINEERING. <br /><br /><br /><br />

            I'M PASSIONATE ABOUT TECHNOLOGY, PHOTOGRAPHY, CHALLENGES AND SOLVING PROBLEMS. <br />
            I ALSO LOVE RACKING MY BRAINS ON CTFs (CAPTURE THE FLAG) @ HACKTHEBOX. <br /><br /><br /><br />

            SCROLL DOWN TO KNOW MORE ABOUT ME. <br /><br /><br />
            

            HOW ARE YOU FEELING TODAY?<br /><br /><br />
            ...

          </code>
        </pre>
        <div className="links">
          <a
            href="https://www.linkedin.com/in/raulval/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin} alt="Linkedin Logo" />
          </a>
          <a
            href="https://www.github.com/raulval/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={github} alt="Github Logo" />
          </a>
          <a
            href="https://app.hackthebox.com/profile/58032"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SiHackthebox size="2.5em" style={{ color: "white" }} />
          </a>
        </div>
      </div>
    </div>
  );
}
